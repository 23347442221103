<template>
  <div>
    <div class="container">     
      <div class="list list3">
        <div class="listitem1">
          <div class="listbox">
            <div class="name">{{name}}</div>
            <div class="unames">{{idnum}}</div>
            <div class="unames1">{{address1}}</div>
            <div class="unames1">{{mobile}}</div>           
            <div class="unames1">{{address2}}</div>
          </div>
          <div class="listbox1">
            <img :src="picture_url" class="iconkg" />
          </div>         
        </div>
      </div>
      <div class="list list2">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">是否去过疫区</div>
          </div>
          <div class="ts">{{yqtxtflag}}</div>
          <div class="listbox2">
            <div v-if="yqflag == false"><img src="@/assets/kgoff.png" @click="onKg" class="iconkg" /></div>
            <div v-if="yqflag == true"><img src="@/assets/kgon.png" @click="onKg" class="iconkg" /></div>
          </div>        
        </div>
      </div>
      <div class="list list2">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">体温是否正常</div>
          </div>
          <div class="ts">{{twtxtflag}}</div>
          <div class="listbox2">
            <div v-if="flag == false"><img src="@/assets/kgoff.png"  @click="onTw" class="iconkg" /></div>
            <div v-if="flag == true"><img src="@/assets/kgon.png"  @click="onTw" class="iconkg" /></div>
          </div>        
        </div>
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="submit">提交</div>         
        </div>       
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary" @click="onScan">继续扫码</div>         
        </div>       
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="txt">{{company}}</div>           
        </div>
        <div class="ll">
          <div class="txt1">{{card_date}}</div>         
        </div>      
      </div>

    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    let { aesid } = this.$route.query;
    this.aesid = aesid;

    let { data } = await this.axios.post(
      '/ctid/TlZhService/pz_smewm_gg',
      qs.stringify({
        aesid: this.aesid
      })
    );
    if (data.flag === '0') {
        //Toast(data.message);
        this.name = data.data.name;
        this.idnum = data.data.idnum;
        this.mobile = data.data.mobile;
        this.address1 = data.data.memo_2;
        this.address2 = data.data.memo_3;
        this.company = data.data.memo_1;
        var stmp = data.data.log_date;     
        this.card_date = stmp.substring(0,10);  
        this.picture_url = data.data.picture_peo; 
    }

    let url = '';
    let ua = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(ua)) {
      url = location.href.split('#')[0];
    }else{
      url = location.href;
    }

    let config = (
      await this.axios.post(
        '/ctid/testService/jsapisign',
        qs.stringify({
          url: url
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: [// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              'checkJsApi',
							'scanQRCode',
							'getLocation', 
							'chooseImage',//拍照或从手机相册中选图接口
							'previewImage',//预览图片接口
							'uploadImage',//上传图片接口
							'downloadImage',//下载图片接口
							'getLocalImgData'
        ] 
    });
   
  },
  data() {
    return {
      data: {
        kgflag: false
      },
      aesid:'',
      picture_url:'/img/zwzp.png',
      name:'',
      idnum:'',
      mobile:'',
      address1:'',
      address2:'',
      company:'',
      card_date:'',     
      update_date:'',
      imgurl:'/img/jz.png',
      picname:'',
      yqtxtflag: '否',
      twtxtflag: '是',
      yqflag: false,
      txzflag: false,
      rlflag: false,
      flag: true
    };
  },
  methods: {
    onScan() {
      window.wx.scanQRCode({
        needResult: 1,
        scanType: ['qrCode', 'barCode'],
        success: res => {
          let result = res.resultStr;
          window.location.href = result;
          //Dialog({ message: result });
        },
        error: res => {
          Dialog({ message: res });
        }
      });
    },
    onKg() {
      this.yqflag=!this.yqflag;
      if(this.yqflag){
        this.yqtxtflag='是';
      }else{
        this.yqtxtflag='否';
      }     
    },
    onTw() {
      this.flag=!this.flag;
      if(this.flag){
        this.twtxtflag='是';
      }else{
        this.twtxtflag='否';
      } 
    },   
    async submit(){
      
      var yqtxt = '';
      var twtxt = '';
      if(this.yqflag){
        yqtxt='1';
      }else{
        yqtxt='2';
      }
      if(this.flag){
        twtxt='1';
      }else{
        twtxt='2';
      }
      let { data } = await this.axios.post(
        '/ctid/TlZhService/lnk_log_gg',
        qs.stringify({
          aesid: this.aesid,
          yq: yqtxt,
          tw: twtxt
        })
      );
      if (data.flag === '0') {
        Dialog({ message: "<img src='/img/cg.png' width=100px height=60px/></br>"+data.message });
      }else {
        Dialog({ message: "<img src='/img/sb.png' width=100px height=60px/></br>"+data.message });        
      } 

    }
    
  }
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .iconkg {
      width: 100px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
  .listitem1 {
    overflow: hidden;
    height: 370px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 60px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .iconkg {
        margin-top: 34px;
        width: 225px;   
        height: 300px;  
        float: right;
        margin-right: 34px;
        margin-bottom: 34px;
        border-radius: 8px;
      }    
    }   
  }
  .listitem2 {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    display: flex;
    &.mt {
      height: 270px;
    }
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 23px;
        margin-left: 34px;
      }
      .name1 {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .ts {
      height: 60px;
      width: 50px;
      line-height: 60px;
      overflow: hidden;
      font-size: 30px;
      margin-top: 22px;
      margin-right: 0px;
      display: block;
      float: right;
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .unames {
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        color: #c90c0c;
        margin-top: 22px;
        margin-right: 34px;
        float: right;
      }
      .iconkg {
        width: 100px;
        margin-top: 22px;
        margin-right: 34px;
        display: block;
        float: right;
      }
      
      .iconkg1 {
        margin-top: 34px;
        width: 320px;   
        height: 203px;  
        float: right;
        margin-right: 34px;
      }

      
    }
    .listbox2 {
      overflow: hidden;
      .unames {
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        color: #c90c0c;
        margin-top: 22px;
        margin-right: 34px;
        float: right;
      }
      .iconkg {
        width: 120px;
        margin-top: 10px;
        margin-right: 20px;
        display: block;
        float: right;
      }     
    }
    
  }
  
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  &.list3 {
    margin-top: 10px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
    .txt {
      margin-top: 35px;
      width: 100%;
      height: 50px;
      text-align: center;
      overflow: hidden;
      font-size: 32px;
      line-height: 50px;
    }
    .txt1 {
      width: 100%;
      height: 50px;
      text-align: center;
      overflow: hidden;
      font-size: 32px;
      line-height: 50px;
    }
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>